import React from "react";
import "./intro1.css";
import { FaLinkedinIn } from "react-icons/fa";
import { useTypewriter, Cursor } from "react-simple-typewriter";

const Intro = () => {
  const [text] = useTypewriter({
    words: ["Developer", "Data Analyst", "Front End Developer"],
    loop: {},
    typeSpeed: 120,
    deleteSpeed: 100,
  });

  return (
    <div className="intro_container" id="intro">
      <div className="content">
        <span className="subheading">HEY! I am,</span>
        <h1 className="heading">Aditya Panchwagh</h1>
        <h6 className="intro_animation">
          I am a{" "}
          <span style={{ color: "black" }} className="intro_animation">
            {text}
          </span>
          <span style={{ color: "red" }}>
            <Cursor cursorStyle="|" />
          </span>
        </h6>
        <div className="home_buttons">
          <a
            href="Aditya_Panchwagh.pdf"
            download="ADITYA PANCHWAGH CV.pdf"
            className="introcv"
          >
            <button className="btn">
              <b>CV</b>
            </button>
          </a>
          <a
            href="https://www.linkedin.com/in/aditya-panchwagh-a19p/"
            target="_blank"
          >
            <button className="lb">
              <FaLinkedinIn />
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Intro;
