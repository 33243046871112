import React from 'react';
import emailjs from '@emailjs/browser';
import { FaLinkedinIn } from "react-icons/fa";
import { FaGithub } from 'react-icons/fa';
import "./contact.css"

const Contact = () => {

  function sendEmail(e){
    e.preventDefault();

      emailjs.sendForm
        ('service_r3o3jko',
        'template_9v4a1ka',
        e.target, 
        '-S2z_Ctc_tBICDSiw'
        )
        .then
        ( res => {
        console.log(res);
        alert("Message Sent")

        e.target.reset();
      }).catch(err => console.log(err));
  }

  return (
    <section id="contact" class="contact">
    <div class="container">

      <div class="section-title">
        <span>Contact Me</span>
        <h2>Contact Me</h2>
      </div>

      <div class="row">

        <div class="col-lg-6">

          <div class="row" id='boxes'>
            <div class="col-md-12">
              <div class="info-box">
                <i class="bx bx-share-alt"></i>
                <h3>Social Profiles</h3>
                <div class="social-links">
                  <a href="https://www.linkedin.com/in/aditya-panchwagh-a19p/" target="_blank" class="google-plus"><i class="bi bi-linkedin"><FaLinkedinIn /></i></a>
                  <a href="https://github.com/Aditya-Panchwagh" target="_blank" class="linkedin"><i class="bi bi-github"><FaGithub /></i></a>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="info-box mt-4">
                <i class="bx bx-envelope"></i>
                <h3>Email Me</h3>
                <p>apanchwagh19@gmail.com</p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="info-box mt-4">
                <i class="bx bx-phone-call"></i>
                <h3>Call Me</h3>
                <p>+44 7933022966</p>
              </div>
            </div>
          </div>

        </div>

        <div class="col-lg-6" id='box'>
          <form className='email-form' onSubmit={sendEmail}>
            <h4>Get in Touch</h4>
            <div className="row">
              <div className="col-md-6 form-group">
              <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required/>
              </div>
              <div className="col-md-6 form-group mt-3 mt-md-0">
                <input type="email" className="form-control" name="user_email" id="user_email" placeholder="Your Email" required/>
              </div>
            </div>
            <div className="form-group mt-3">
              <textarea className="form-control" name="message" rows="4" placeholder="Message"></textarea>
          </div>
          <div className="text-center" id='send_button'><button type="submit" value="Send">Send Message</button></div>
          </form>
        </div>
      </div>
    </div>
  </section>
  );
}

export default Contact;
