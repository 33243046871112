// Navbar.js

import React from "react";
import "./navbar.css"; // Import your CSS file for styling

function Navbar() {
  return (
    <nav className="navbar">
      <p>
        <a href="#intro" className="navTitle">
          Aditya
        </a>
      </p>
      <ul className="navbar-list">
        <li>
          <a href="#intro">Home</a>
        </li>
        <li>
          <a href="#about">About Me</a>
        </li>
        <li>
          <a href="#skills">Skills</a>
        </li>
        <li>
          <a href="#project">Projects</a>
        </li>
        <li>
          <a href="#contact">Contact Me</a>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
