import React from 'react';
import "./project.css";
import Gazetteer from "../../assets/Gazetteer (3).jpg";
import Casualty from "../../assets/casualty.png"

const project = () => {
  return (
    <div className='p' id='project'>
        <h2 className='pHeading'>Projects</h2>
      <div className="container">
        <div className="project1">
            <h3 className='gP'>Gazetteer Project</h3>
            <div className="proImg1">
                <img src={Gazetteer} alt="Gazetter Project Photo" />
            </div>
            <div className="summary">
            <p><b>Aim:</b><br /></p>
            <p>
                • To create a responsive application, which would ask and display the user's location, and then display all the related information for the selected country through the use of various API's.</p>
                <p><b>Methods and Results: </b> <br /></p>
                <p>
                    • Used HTML, CSS and JavaScript with JQuery modules that use PHP server-based components to source and display data <br />
                    • There is a dropdown menu option enabling the user to select other countries. Upon selecting a country, it's borders will be highlighted and relevant data is provided associated with that country. <br />                   
                    • Some of the functionalities in it includes a currency converter, which allows the user to check currency conversions, gives current weather updates, current time and wikipedia links to the countries. 
                </p>
                <p>
                    <b>Skills:- </b> <br />
                    HTML,CSS,JS, JQuery for DOM manipulation, AJAX calls to php routines and used cURLs to access API information.
                </p>
                <a href="https://adityapanchwagh.co.uk/project1/" target="_blank"><button className='demoBtn'>Live Demo</button></a>
            </div>
        </div>

        <div className="project2">
            <h3>Traffic Collision Analysis</h3>
            <div className="proImg2">
                <img src={Casualty} alt="Region wise Casualty in the UK" />
            </div>
            <div className="summary">
                <p><b>Aim:</b><br /></p>
                <p>
                    •To understand the major accident hotspots in the UK and to create data visualizations based on the results obtained</p>
                <p><b>Methods and Results: </b> <br /></p>
                <p>
                    • Used both Power BI and Tableau, along with MS-Excel to create dashboards and generate reports featuring diverse
                        data visualizations highlighting prominent traffic collision hotspots, primarily within the London region of the
                        United Kingdom.
                        <br />
                    • Used strong and advanced SQL queries to manipulate and extract data, facilitating comprehensive querying
                        through Power BI. This meticulous approach enabled in-depth data analysis, particularly focusing on collision
                        severity and the types of vehicles responsible for the collisions.
                        <br />
                </p>
                <p>
                    <b>Skills:- </b> <br />
                   Data Analysis, SQL, Power Bi, Tableau, MS-Excel, Data Visualization 
                </p>
                <a href="https://github.com/Aditya-Panchwagh/Traffic-Collision-Analysis.git" target="_blank"><button className='demoBtn'>Code</button></a>
            </div>
        </div>

        <div className="project3">
            <h3>Panchang Intelligence</h3>
            <div className="summary">
            <p><b>Aim:</b><br /></p>
            <p>
                • To create a weather prediction system across India and the world which would be blend of antient Indian technology
                    and current systems</p>
                <p><b>Methods and Results: </b> <br /></p>
                <p>
                    • Designed and implemented a highly accurate Weather Prediction System with 83% accuracy,using Data Science methods and Expert Knowledge<br />
                    • Leveraged project management software- Git Lab to track the progress of the project and manage versions. <br />
                    • Employing deep learning algorithms and time series forecasting techniques to predict weather parameters such as temperature, humidity, and other relevant metrics.<br />
                    • Created a Responsive website and successfully deployed it showcasing all the results achieved. <br />
                    • Various models were developed based on diverse book-derived rules and tailored for different global locations, with meticulous observation records maintained in an Excel Sheet. <br />
                    • Published a research paper showcasing the results obtained in Indian Journal of Science and Technology</p>
                <p>
                    <b>Skills:- </b> <br />
                    Python, Data Science, Machine Learning Algorithms, TimeSeries Forcasting, HTML, CSS, JS, JQuery, Leaflet Maps.
                </p>
                
                <a href="https://indjst.org/articles/assessment-of-accuracy-of-indian-almanac-for-daily-rainfall-prediction" target="_blank"><button className='demoBtn' id='RPBtn'>Research Paper</button></a>
            </div>
        </div>
        <div className="project4">
            <h3>Local Model Agnostic Techniques</h3>
            <div className="summary">
            <p><b>Aim:</b><br /></p>
                <p>
                • To understand different Local model agnostic techniques (LIME and SHAP) used for explaining the outcome given
                    by the machine learning models.</p>
                <p><b>Methods and Results: </b> <br /></p>
                <p>
                    • Effectively balanced the IBM-HR attrition dataset through the SMOTE balancing technique and data pre-processing techniques<br />
                    • Utilizing matplotlib and seaborn libraries, the basic trends within the data were successfully visualized, offering valuable insights for decision-making purposes.<br />
                    • Following this, used various machine learning algorithms including KNN, Random Forest, and XG Boost were employed for classification model.<br />
                    • Local model-agnostic techniques, specifically LIME and SHAP methods, were applied on the aforementioned machine learning algorithms to determine the contribution of each attribute to the final decision made.<br />
                    • The results obtained include an enhanced model performance using KNN, Random Forest, and XG Boost algorithms, achieving an accuracy of 90% on the test data. <br />
                </p>
                <p>
                    <b>Skills:- </b> <br /> 
                    Python, Data Analytics, Machine Learning Algorithms, Model Agnistic Techniques, Data Visualisation, Matplotlib, Seaborn.
                </p>
                
                <a href="https://github.com/Aditya-Panchwagh/Local-Model-Agnostic-Techniques.git" target="_blank"><button className='demoBtn'>Code</button></a>
            </div>
        </div>

        
      </div>
    </div>
  );
}

export default project;
