import React from 'react';
import { FaLinkedinIn } from "react-icons/fa";
import { FaGithub } from 'react-icons/fa';
import "./footer.css"

const Footer = () => {
  return (
    <div className='f' id='footer'>
        <div className='container'>
            <div className="footerContents">
                <div class="social-links">
                <a href="https://www.linkedin.com/in/aditya-panchwagh-a19p" target="_blank" class="google-plus"><i class="bi bi-linkedin" id='Lb'><FaLinkedinIn /></i></a>
                <a href="https://github.com/Aditya-Panchwagh" target="_blank" class="linkedin"><i class="bi bi-github" id='Gb'><FaGithub /></i></a>
            </div>
            <div className="footerTitle">
                <h3><b>Made By Aditya Panchwagh</b></h3>
            </div>
            </div>
            </div>
    </div>
  );
}

export default Footer;
