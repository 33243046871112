import React from 'react';
import CIcon from '@coreui/icons-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHtml5 } from '@fortawesome/free-brands-svg-icons';
import { faCss3 } from '@fortawesome/free-brands-svg-icons';
import { faBootstrap } from '@fortawesome/free-brands-svg-icons';
import { faJs } from '@fortawesome/free-brands-svg-icons';
import { faReact } from '@fortawesome/free-brands-svg-icons';
import { faPhp } from '@fortawesome/free-brands-svg-icons';
import { faPython } from '@fortawesome/free-brands-svg-icons';
import {cibMysql} from '@coreui/icons';

import "./skills.css"


const Skills = () => {
  return (
    <div className="s" id="skills">
        <h2 className='skillHeading'>My Skills</h2>
        <div className="container">
            <div className="html">
                <p className='sI'><FontAwesomeIcon icon={faHtml5}/></p>
                <p className='sN'>HTML5</p>
            </div>
            <div className="css">
                <p className='sI'><FontAwesomeIcon icon={faCss3}/></p>
                <p className='sN'>CSS</p>
            </div>
            <div className="css">
                <p className='sI'><FontAwesomeIcon icon={faBootstrap}/></p>
                <p className='sN'>Bootstrap</p>
            </div>
            <div className="css">
                <p className='sI'><FontAwesomeIcon icon={faJs}/></p>
                <p className='sN'>JS</p>
            </div>
            <div className="css">
                <p className='sI'><FontAwesomeIcon icon={faReact}/></p>
                <p className='sN'>React</p>
            </div>
            <div className="css">
                <p className='sI'><FontAwesomeIcon icon={faPhp}/></p>
                <p className='sN'>PHP</p>
            </div>
            <div className="css">
                <p className='sI'><FontAwesomeIcon icon={faPython}/></p>
                <p className='sN'>Python</p>
            </div>
            <div className="css">
                <p className='mI'><CIcon icon={cibMysql}/></p>
            </div>
        </div>
    </div>
  );
}

export default Skills;
