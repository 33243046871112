import React from 'react';
import "./about.css"
import img from "../../assets/adi_pic.jpeg"
import { Fade } from "react-awesome-reveal";
import { FaChevronCircleDown } from "react-icons/fa";


const About = () => {
  return (
    <> 
    <div className="a" id="about">
      <h1 className='aMHeading'><b>About Me</b></h1>
    <div className='container'>
      <div className="profileImg">
        <img src={img} alt="Profile Image" />
      </div>
      <div className="aboutMeText">
        <p className='first_para'>A Computer Science Engineer with Masters in Data Science and Analytics from the University of Hertfordshire, Hatfield, UK.</p>
        <p className='first_para'>I am a goal driven individual, willing to learn and develop new skills. I like to analyze and visualiza data to gain meaningful insights from it.
               Apart from data analysis, I enjoy making and deploying websites.</p>
        <div className="myJourney">
          <h4><b className='Jt'>My Journey</b></h4>
          <Fade>
            <p>B-Tech Computer Secience Engineering - MIT ADT University Pune (2017-2021)</p>
            <p className='arrow'><FaChevronCircleDown rotation={90}/></p>
          </Fade>
          <Fade>
            <p id='intern'>Intern - Tech Mahindra (2020-2021)</p>
            <p className='arrow'><FaChevronCircleDown /></p>
          </Fade>
          <Fade>
            <p>MSc Data Science and Analytics - University of Hertfordshire, UK (2021-2022)</p>
          </Fade>
        </div>
      </div>
    </div>
    </div>
    </>
  );
}

export default About;
